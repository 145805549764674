<template>
  <div>
    <div class="card">
      <div class="card-header">
        Lojas
        <b-button class="float-right" v-if="$route.name != router_actions.route_new" @click="novo" variant="primary">Novo</b-button>
      </div>
      <div class="card-body">
        <div>
          <v-alert v-show="show_msg" :type="msg_type">
            {{ errors }}
          </v-alert>
        </div>
        <b-table :fields="column" striped hover :items="items">
          <template #cell(actions)="row">
            <b-button size="sm" variant="primary" @click="editStore(row.item.id)"> Editar </b-button>
            <b-button size="sm" variant="danger" @click="deletarStore(row.item.id, row.index)"> Deletar </b-button>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapState } from "vuex";
import ApiService from "@/core/services/api.service";
export default {
  name: "User",
  data() {
    return {
      router_actions: {
        route_new: 'stores-new',
        route_edit: 'stores-edit'
      },
      description_length: 60,
      column: [
        {
          key: "id",
          thClass: 'd-none',
          tdClass: 'd-none',
          sortable: true,
          label: "Id da Loja",
        },
        {
          key: "sto_title",
          sortable: true,
          label: "Nome da Loja",
        },
        {
          key: "sto_description",
          sortable: true,
          label: "Descrição",
        },
        {
          key: "sto_city",
          sortable: true,
          label: "Cidade",
        },
        {
          key: "actions",
          sortable: false,
          label: "Acões",
        },

      ],
      errors: [],
      show_msg: false,
      msg_type: null,
      items: null,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/" },
      { title: "Listagem de Lojas", route: '/stores' }
    ]);

    ApiService.get("stores")
      .then(({ data }) => {
        this.items = data.map((e, i)=>{
          if(e.sto_description.length > this.description_length){
              e.sto_description = e.description.substring(0, this.description_length);
          }
          return e;
        });
      })
      .catch(({ response }) => {
        this.errors = response.data.errors;
        this.msg_type = "error";
        this.show_msg = true;
      });
  },
  computed: {
    ...mapState({
      user: state => state.auth.user
    }),
  },
  methods: {
    novo() {
      this.$router.push( {name: this.router_actions.route_new})
    },
    editStore(id) {
      this.$router.push('/stores/edit/'+ id);
    },

    deletarStore(id, index) {

      ApiService.delete("stores/"+id)
        .then(({ data }) => {
          this.errors = data.message;
          this.show_msg = true;
          this.msg_type = "success";
          this.items.splice(index,1);
        })
        .catch(({ response }) => {
          this.errors = response.data.errors;
          this.msg_type = "error";
          this.show_msg = true;
        });
    },
  },
  components: {},
};
</script>
